import { memo, type ReactNode, type FC, useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TutorBotMessageContent } from 'TutorBotMessageContent';
import { useTriggerCallbackOnInterval } from 'useTriggerCallbackOnInterval';
import './locales/ReviewPreviousMaterialVoiceAgentRoom/ReviewPreviousMaterialIntro-en.json';
import FrontRoyalSpinner from 'FrontRoyalSpinner';
import { DELAY_BETWEEN_PRETEND_MESSAGES_MS, PRETEND_MESSAGE_SPINNER_DURATION_MS } from 'TutorBotConversation';

function IntroMessage({ children, shouldShowSpinner }: { children: ReactNode; shouldShowSpinner: boolean }) {
    const [showSpinner, setShowSpinner] = useState(shouldShowSpinner);

    useEffect(() => {
        let timeout: NodeJS.Timeout;
        if (showSpinner) {
            timeout = setTimeout(() => {
                setShowSpinner(false);
            }, PRETEND_MESSAGE_SPINNER_DURATION_MS);
        }
        return () => clearTimeout(timeout);
    }, [shouldShowSpinner, showSpinner]);

    return (
        <TutorBotMessageContent
            className="first:mt-auto"
            isHumanMessage={false}
            contentType="text"
            isLoading={showSpinner}
        >
            {showSpinner ? <FrontRoyalSpinner className="no-top-margin no-delay static-height m-0" /> : children}
        </TutorBotMessageContent>
    );
}

const IntroMessageLocaleKeys = ['good_morning', 'the_session_will_use', 'just_find_a_quiet_spot'] as const;

const ReviewPreviousMaterialIntroComponent: FC = () => {
    const { t } = useTranslation('back_royal', {
        keyPrefix: 'ReviewPreviousMaterialVoiceAgentRoom.ReviewPreviousMaterialIntro',
    });

    const [visibleMessages, setVisibleMessages] = useState<string[]>([]);

    const showNextMessage = useCallback((messageToAdd: (typeof IntroMessageLocaleKeys)[number]) => {
        setVisibleMessages(prev => [...prev, messageToAdd]);
    }, []);

    useTriggerCallbackOnInterval({
        listToTrigger: IntroMessageLocaleKeys,
        callback: showNextMessage,
        intervalMs: DELAY_BETWEEN_PRETEND_MESSAGES_MS,
        delayOnStart: false,
    });

    return (
        <>
            {visibleMessages.map(messageKey => (
                <IntroMessage key={messageKey} shouldShowSpinner={messageKey === visibleMessages.at(-1)}>
                    {t(messageKey)}
                </IntroMessage>
            ))}
        </>
    );
};

export const ReviewPreviousMaterialIntro = memo(
    ReviewPreviousMaterialIntroComponent,
) as typeof ReviewPreviousMaterialIntroComponent;
