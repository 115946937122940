import angularModule from 'Settings/angularModule/scripts/settings_module';
import template from 'Settings/angularModule/views/tuition_and_registration/cohort_registration_no_plan_selection.html';
import cacheAngularTemplate from 'cacheAngularTemplate';
import { getProgramInclusion, getTuitionContract, getAoiRecord } from 'Users';
import { selectablePaymentSituationForTuitionPlanId } from 'PaymentSituation';
import { getProduct } from 'Stripe';
import { AoiRecordType } from 'ProgramAoi';
import { isPartialScholarship } from 'Scholarship';
import { formatCurrencyForDisplay } from 'Currency';
import { getProgramConfigValue } from 'Program';
import { getDefaultSelectedPaymentOption, getPaymentConfig } from './getPaymentConfig';
import {
    attachDeferralModalFn,
    attachOpenStripeCheckoutSessionFn,
    attachRegisterButtonWatchFn,
    attachRegisterEnabledWatchFn,
    attachPaymentConfigStateWatchFn,
    attachCohortDatesFn,
} from './CohortRegistrationDirHelpers';
import { PaymentTypeOption, PaymentView } from './TuitionAndRegistration.types';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('cohortRegistrationNoPlanSelection', [
    '$injector',

    function factory($injector) {
        const $rootScope = $injector.get('$rootScope');
        const ErrorLogService = $injector.get('ErrorLogService');
        const ConfigFactory = $injector.get('ConfigFactory');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                // This is called from inside the smallprint locale
                launchProgramGuide: '&',
                section: '<',
            },
            link(scope) {
                //-------------------------
                // Initialization
                //-------------------------
                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                scope.creditCardPaymentFeeRate = ConfigFactory.getSync().creditCardPaymentFeeRate();
                scope.supportsBuyNowPayLater = getProgramConfigValue(
                    scope.currentUser.relevantCohort.programType,
                    'supportsBuyNowPayLater',
                );

                // A user should never see this page if their active AOI record isn't a ProgramInclusion.
                scope.programInclusion = getProgramInclusion(scope.currentUser);
                scope.tuitionContract = getTuitionContract(scope.currentUser);

                // When a user sees this page, their active AOI record is a ProgramInclusion, because
                // they've registered. We need to recursively get the AdmissionOffer so we can read the
                // payment situations off of it.
                scope.admissionOffer = getAoiRecord(scope.currentUser, AoiRecordType.AdmissionOffer);

                // It shouldn't be possible to get here without a TuitionContract or an AdmissionOffer.
                // If we do, we want to know about it. If this error is logged, the UI is definitely broken for this user.
                if (!scope.tuitionContract || !scope.admissionOffer) {
                    ErrorLogService.notifyInProd(
                        'Trying to render cohortRegistrationNoPlanSelection UI without a TuitionContract or AdmissionOffer',
                        undefined,
                        {
                            userId: scope.currentUser.id,
                        },
                    );
                    $rootScope.goHome();
                }

                scope.cohortSectionOffer = scope.admissionOffer?.cohortSectionOffers[0];
                scope.numOfferedScholarships = scope.cohortSectionOffer?.offeredScholarships?.length ?? 0;
                scope.totalScholarshipForDisplay = formatCurrencyForDisplay(
                    scope.cohortSectionOffer?.cumulativeOfferedScholarship?.amount,
                );

                scope.selectablePaymentSituation = selectablePaymentSituationForTuitionPlanId(
                    scope.cohortSectionOffer?.selectablePaymentSituations,
                    scope.tuitionContract?.tuitionPlan.id,
                );

                scope.selectedPaymentTypeProxy = { value: null };
                scope.activePaymentView = PaymentView.select_payment; // needed for getRegisterButtonState()

                scope.hasPartialScholarship = isPartialScholarship(scope.tuitionContract?.cumulativeScholarship);

                //-------------------------
                // Payment Config
                //-------------------------

                scope.$watchGroup(
                    [
                        'paymentConfigState',
                        'creditCardPaymentFeeRate',
                        'supportsBuyNowPayLater',
                        'currentUser.country',
                        'currentUser.state',
                    ],
                    ([paymentConfigState, creditCardPaymentFeeRate, supportsBuyNowPayLater, country, state]) => {
                        scope.paymentConfig = getPaymentConfig({
                            paymentConfigState,
                            selectedPaymentSituation: scope.selectablePaymentSituation,
                            creditCardPaymentFeeRate,
                            section: scope.section,
                            supportsBuyNowPayLater,
                            country,
                            state,
                        });

                        if (!scope.paymentConfig) return;

                        const currentSelectedTypeIsAvailable = scope.paymentConfig
                            .availablePaymentTypeOptions()
                            .includes(scope.selectedPaymentTypeProxy.value);

                        // Preserve the current payment type selection if the type is still available per the new PaymentConfig
                        scope.selectedPaymentTypeProxy.value = currentSelectedTypeIsAvailable
                            ? scope.selectedPaymentTypeProxy.value
                            : getDefaultSelectedPaymentOption(scope.paymentConfig);
                    },
                );

                //-------------------------
                // Registration helpers
                //-------------------------

                // We need to call these after initialization above because they use things on the scope.
                attachDeferralModalFn(scope, $injector, scope.tuitionContract?.cumulativeScholarship);
                attachOpenStripeCheckoutSessionFn(scope, $injector);
                attachRegisterButtonWatchFn(scope);
                attachRegisterEnabledWatchFn(scope);
                attachPaymentConfigStateWatchFn(scope);
                attachCohortDatesFn(scope);

                //-------------------------
                // Locale Keys
                //-------------------------
                if (scope.hasPartialScholarship) {
                    scope.narrativeKey = 'settings.tuition_and_registration.narrative_partial_scholarship';
                    scope.graphicTitleKey = 'settings.tuition_and_registration.graphic_partial_scholarship_title';
                    scope.graphicSubtitleKey = 'settings.tuition_and_registration.graphic_partial_scholarship_subtitle';
                } else {
                    scope.narrativeKey = 'settings.tuition_and_registration.narrative_no_scholarship';
                    scope.graphicTitleKey = 'settings.tuition_and_registration.graphic_no_scholarship_title';
                    scope.graphicSubtitleKey = 'settings.tuition_and_registration.graphic_no_scholarship_subtitle';
                }

                scope.tuitionDetailsLabelKey = scope.hasPartialScholarship
                    ? 'settings.tuition_and_registration.tuition_and_scholarship_details'
                    : 'settings.tuition_and_registration.tuition_details';

                //-------------------------
                // Checkout Session handling
                //-------------------------

                // This is side effectful - it is guaranteed to navigate the user away from this page.
                // If they're able to register in this environment, we'll send them to a Stripe Checkout Session.
                // If they're unable to register in this environment (Cordova), we'll send them to a system browser.
                scope.handleRegisterButtonClick = () => {
                    const shouldOpenBankDetailsModal =
                        scope.activePaymentView === PaymentView.select_payment &&
                        scope.selectedPaymentTypeProxy.value === PaymentTypeOption.wire_transfer;

                    if (shouldOpenBankDetailsModal) {
                        // do not take to Stripe, just provide wire transfer instructions
                        scope.openBankDetailsModal();
                        return;
                    }

                    scope.openStripeCheckoutSessionOrBrowser(scope, $injector, {
                        admission_offer_id: scope.admissionOffer.id,
                        payment_type: {
                            [PaymentTypeOption.us_bank_account]: 'ach',
                            [PaymentTypeOption.card]: 'card',
                            [PaymentTypeOption.buy_now_pay_later]: 'bnpl',
                        }[scope.selectedPaymentTypeProxy.value],
                        product_id: getProduct(scope.admissionOffer.programType).id,
                        selected_tuition_plan_id: scope.tuitionContract.tuitionPlan.id,
                        tuition_contract_id: scope.tuitionContract.id,
                    });
                };
            },
        };
    },
]);
