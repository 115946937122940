window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.ReviewPreviousMaterialVoiceAgentRoom = window.locales.am.ReviewPreviousMaterialVoiceAgentRoom || {};window.locales.am.ReviewPreviousMaterialVoiceAgentRoom.ReviewPreviousMaterialIntro = window.locales.am.ReviewPreviousMaterialVoiceAgentRoom.ReviewPreviousMaterialIntro || {};window.locales.am.ReviewPreviousMaterialVoiceAgentRoom.ReviewPreviousMaterialIntro = {...window.locales.am.ReviewPreviousMaterialVoiceAgentRoom.ReviewPreviousMaterialIntro, ...{
    "good_morning": "ምልካም እድል!",
    "the_session_will_use": "ለመገምገም ዝግጁ ነዎት? ይህ ክፍለ ጊዜ የላቀ የድምጽ ሁነታን ይጠቀማል እና ከ 5 ደቂቃዎች ያነሰ ጊዜ ሊወስድ ይገባል. በመጨረሻ፣ የቀደመውን ትምህርት ቁልፍ ነጥቦች በደንብ ይገነዘባሉ።",
    "just_find_a_quiet_spot": "ጸጥ ያለ ቦታ ያግኙ፣ ማይክሮፎንዎን ይያዙ እና ለመጀመር ዝግጁ ሲሆኑ ከታች ያለውን ቁልፍ ጠቅ ያድርጉ። "
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.ReviewPreviousMaterialVoiceAgentRoom = window.locales.ar.ReviewPreviousMaterialVoiceAgentRoom || {};window.locales.ar.ReviewPreviousMaterialVoiceAgentRoom.ReviewPreviousMaterialIntro = window.locales.ar.ReviewPreviousMaterialVoiceAgentRoom.ReviewPreviousMaterialIntro || {};window.locales.ar.ReviewPreviousMaterialVoiceAgentRoom.ReviewPreviousMaterialIntro = {...window.locales.ar.ReviewPreviousMaterialVoiceAgentRoom.ReviewPreviousMaterialIntro, ...{
    "good_morning": "صباح الخير!",
    "the_session_will_use": "هل أنت مستعد للمراجعة؟ ستستخدم هذه الجلسة وضعًا صوتيًا متقدمًا، ولن تستغرق أكثر من خمس دقائق. في النهاية، ستكون قد فهمت جيدًا النقاط الرئيسية للدرس السابق.",
    "just_find_a_quiet_spot": "كل ما عليك فعله هو العثور على مكان هادئ، وإحضار الميكروفون الخاص بك، والنقر على الزر أدناه عندما تكون مستعدًا للبدء. "
}
};window.locales.en = window.locales.en || {};window.locales.en.ReviewPreviousMaterialVoiceAgentRoom = window.locales.en.ReviewPreviousMaterialVoiceAgentRoom || {};window.locales.en.ReviewPreviousMaterialVoiceAgentRoom.ReviewPreviousMaterialIntro = window.locales.en.ReviewPreviousMaterialVoiceAgentRoom.ReviewPreviousMaterialIntro || {};window.locales.en.ReviewPreviousMaterialVoiceAgentRoom.ReviewPreviousMaterialIntro = {...window.locales.en.ReviewPreviousMaterialVoiceAgentRoom.ReviewPreviousMaterialIntro, ...{
    "good_morning": "Good morning!",
    "the_session_will_use": "Ready to review? This session will use advanced voice mode and should take less than 5 minutes. By the end, you’ll have a solid grasp of the previous lesson’s key points.",
    "just_find_a_quiet_spot": "Just find a quiet spot, grab your microphone, and click the button below when you are ready to start. "
}
};window.locales.es = window.locales.es || {};window.locales.es.ReviewPreviousMaterialVoiceAgentRoom = window.locales.es.ReviewPreviousMaterialVoiceAgentRoom || {};window.locales.es.ReviewPreviousMaterialVoiceAgentRoom.ReviewPreviousMaterialIntro = window.locales.es.ReviewPreviousMaterialVoiceAgentRoom.ReviewPreviousMaterialIntro || {};window.locales.es.ReviewPreviousMaterialVoiceAgentRoom.ReviewPreviousMaterialIntro = {...window.locales.es.ReviewPreviousMaterialVoiceAgentRoom.ReviewPreviousMaterialIntro, ...{
    "good_morning": "¡Buen día!",
    "the_session_will_use": "¿Listo para repasar? Esta sesión usará el modo de voz avanzado y durará menos de 5 minutos. Al finalizar, comprenderás a la perfección los puntos clave de la lección anterior.",
    "just_find_a_quiet_spot": "Simplemente busque un lugar tranquilo, tome su micrófono y haga clic en el botón de abajo cuando esté listo para comenzar. "
}
};window.locales.it = window.locales.it || {};window.locales.it.ReviewPreviousMaterialVoiceAgentRoom = window.locales.it.ReviewPreviousMaterialVoiceAgentRoom || {};window.locales.it.ReviewPreviousMaterialVoiceAgentRoom.ReviewPreviousMaterialIntro = window.locales.it.ReviewPreviousMaterialVoiceAgentRoom.ReviewPreviousMaterialIntro || {};window.locales.it.ReviewPreviousMaterialVoiceAgentRoom.ReviewPreviousMaterialIntro = {...window.locales.it.ReviewPreviousMaterialVoiceAgentRoom.ReviewPreviousMaterialIntro, ...{
    "good_morning": "Buongiorno!",
    "the_session_will_use": "Pronti per la revisione? Questa sessione utilizzerà la modalità vocale avanzata e dovrebbe durare meno di 5 minuti. Alla fine, avrete una solida comprensione dei punti chiave della lezione precedente.",
    "just_find_a_quiet_spot": "Trova un posto tranquillo, prendi il microfono e clicca sul pulsante qui sotto quando sei pronto per iniziare. "
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.ReviewPreviousMaterialVoiceAgentRoom = window.locales.zh.ReviewPreviousMaterialVoiceAgentRoom || {};window.locales.zh.ReviewPreviousMaterialVoiceAgentRoom.ReviewPreviousMaterialIntro = window.locales.zh.ReviewPreviousMaterialVoiceAgentRoom.ReviewPreviousMaterialIntro || {};window.locales.zh.ReviewPreviousMaterialVoiceAgentRoom.ReviewPreviousMaterialIntro = {...window.locales.zh.ReviewPreviousMaterialVoiceAgentRoom.ReviewPreviousMaterialIntro, ...{
    "good_morning": "早上好！",
    "the_session_will_use": "准备好复习了吗？本课程将使用高级语音模式，时间应少于 5 分钟。到最后，您将牢牢掌握上一课的要点。",
    "just_find_a_quiet_spot": "只要找到一个安静的地方，拿起麦克风，并在准备开始时单击下面的按钮即可。 "
}
};