import { LocalNotifications } from '@capacitor/local-notifications';
import { type SendbirdPushNotificationPayload } from './sendbirdHelpers.types';

// All APNS notifications and FCM "notification"-type messages are handled and displayed automatically.
// FCM "data"-type messages, which is what Sendbird sends, need to be handled and displayed manually.
// https://firebase.google.com/docs/cloud-messaging/concept-options#notifications_and_data_messages
// https://sendbird.com/docs/chat/sdk/v4/android/push-notifications/managing-push-notifications/set-up-push-notifications-for-fcm#2-step-5-handle-an-fcm-message-payload
export function handleSendbirdFCMPushNotification(payload: SendbirdPushNotificationPayload) {
    // The notification identifier. On Android it's a 32-bit int. So the value should be between -2147483648 and 2147483647 inclusive.
    // https://capacitorjs.com/docs/apis/local-notifications#localnotificationschema
    const signed32BitInt = Math.floor(Math.random() * 4_294_967_295) - 2_147_483_648;

    let body = payload.message;

    if (payload.sender && !payload.message.startsWith(`${payload.sender.name}: `)) {
        body = `${payload.sender.name}: ${payload.message}`;
    }

    LocalNotifications.schedule({
        notifications: [
            {
                title: 'Quantic',
                body,
                sound: 'default',
                id: signed32BitInt,
            },
        ],
    });
}
