import { Tabs, Tab } from '@heroui/react';
import { type FC } from 'react';
import { twMerge } from 'tailwind-merge';
import { type SegmentedControlProps } from './SegmentedControl.types';

export const SegmentedControl: FC<SegmentedControlProps> = ({ segments, classNames, onSegmentChange }) => (
    <Tabs
        variant="underlined"
        color="primary"
        fullWidth
        onSelectionChange={onSegmentChange}
        classNames={{
            wrapper: twMerge([classNames?.wrapper]),
            base: twMerge([classNames?.base]),
            tabList: twMerge([
                'p-0',
                'border-b border-solid border-b-slate-grey-200',
                'overflow-x-visible',
                classNames?.tabList,
            ]),
            tab: twMerge(['data-[hover-unselected=true]:opacity-100', 'pb-5 h-9', classNames?.tab]),
            tabContent: twMerge(['text-base', 'text-black', 'font-semibold', classNames?.tabContent]),
            cursor: twMerge(['w-full', 'bottom-[-1px]', 'rounded-1', 'shadow-none', classNames?.cursor]),
            panel: twMerge(['p-0', classNames?.panel]),
        }}
    >
        {segments.map(({ title, content, disabled }) => (
            <Tab key={title} title={title} disabled={disabled}>
                {content}
            </Tab>
        ))}
    </Tabs>
);
