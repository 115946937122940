import cacheAngularTemplate from 'cacheAngularTemplate';
import angularModule from 'Settings/angularModule/scripts/settings_module';
import template from 'Settings/angularModule/views/change_password.html';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('changePassword', [
    '$injector',

    function factory($injector) {
        const DeviseClient = $injector.get('DeviseClient');
        const ngToast = $injector.get('ngToast');
        const TranslationHelper = $injector.get('TranslationHelper');
        const $rootScope = $injector.get('$rootScope');
        const safeApply = $injector.get('safeApply');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                submitText: '<',
            },
            link(scope) {
                //---------------------------
                // Initialization
                //---------------------------

                // Setup localization keys
                const translationHelper = new TranslationHelper('settings.change_password');

                //-------------------------
                // Navigation / Actions
                //-------------------------

                scope.preventSubmit = false;
                scope.form = {};

                scope.updatePassword = () => {
                    scope.preventSubmit = true;

                    DeviseClient.updatePassword(scope.form)
                        .then(
                            () => {
                                scope.preventSubmit = false;

                                ngToast.create({
                                    content: translationHelper.get('password_updated'),
                                    className: 'success',
                                });
                                scope.form = {};
                                scope.changePasswordForm?.$setPristine();
                            },
                            response => {
                                scope.preventSubmit = false;

                                // NOTE: this response object is ideal for form validators. using toasts for now to remain
                                // consistent, but we might want to revisit
                                let message = '';
                                if (response?.data?.errors && !Array.isArray(response.data.errors)) {
                                    angular.forEach(response.data.errors, (fieldErrors, field) => {
                                        fieldErrors.forEach(error => {
                                            message += `${
                                                (message.length > 0 ? '<br>' : '') +
                                                (field === 'password'
                                                    ? translationHelper.get('password')
                                                    : translationHelper.get('password_confirmation'))
                                            } ${error}`;
                                        });
                                    });
                                } else {
                                    message = translationHelper.get('try_again');
                                }

                                ngToast.create({
                                    content: message,
                                    className: 'danger',
                                });
                            },
                        )
                        .finally(() => {
                            safeApply($rootScope);
                        });
                };
            },
        };
    },
]);
