import { setupBrandNameProperties, setupBrandScopeProperties, targetBrandConfig } from 'AppBranding';
import { NavigationSectionKey, navigationHelper } from 'NavigationHelpers';
import { getAvailableNavigationSections } from 'NavigationSections';
import cacheAngularTemplate from 'cacheAngularTemplate';
import { formattedUserFacingMonthDayLong, subtractDays } from 'DateHelpers';
import {
    getHasOfferOrIncludedOrCompleted,
    getHasPendingAdmissionOfferOrIsNotYetCurrent,
    getAoiRecord,
    getFilteredExecEdEligibilityBundle,
    getRelevantCohorts,
} from 'Users';
import { keyBy } from 'lodash/fp';
import { react2Angular } from 'FrontRoyalReact2Angular';
import { Grades } from 'Grades';
import { ProgramTypeConfigs } from 'Program';
import { getFormsCompletePercent, getFormsProgressMap } from 'FormCompletionHelper';
import { StudentProfileFormConfigs } from 'FormConfigs';
import { hasGraduated } from 'ProgramInclusion';
import angularModule from './settings_module';
import template from '../views/settings.html';
import { getHeaderParamsForTuitionAndRegistration } from './getHeaderParamsForTuitionAndRegistration';
import { SelectProgram } from './select_program/components/SelectProgram';
import { selectProgramConfig } from './select_program/selectProgramConfig';
import { SelectPreapprovedProgram } from './select_program/components/SelectPreapprovedProgram';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.component('grades', react2Angular(Grades, [], ''));
angularModule.component('selectProgram', react2Angular(SelectProgram, ['selectProgramConfig'], '', false));
angularModule.component(
    'selectPreapprovedProgram',
    react2Angular(SelectPreapprovedProgram, ['selectProgramConfig', 'preapprovals'], '', false),
);

angularModule.directive('settings', [
    '$injector',
    function factory($injector) {
        const $location = $injector.get('$location');
        const $rootScope = $injector.get('$rootScope');
        const SiteMetadata = $injector.get('SiteMetadata');
        const isMobileMixin = $injector.get('isMobileMixin');
        const NavigationHelperMixin = $injector.get('Navigation.NavigationHelperMixin');
        const AppHeaderViewModel = $injector.get('Navigation.AppHeader.AppHeaderViewModel');
        const TranslationHelper = $injector.get('TranslationHelper');
        const EditCareerProfileHelper = $injector.get('EditCareerProfileHelper');
        const ClientStorage = $injector.get('ClientStorage');
        const dateHelper = $injector.get('dateHelper');
        const $sce = $injector.get('$sce');
        const Cohort = $injector.get('Cohort');
        const $window = $injector.get('$window');
        const ConfigFactory = $injector.get('ConfigFactory');
        const DialogModal = $injector.get('DialogModal');
        const navigationHelpers = navigationHelper();
        const { loadUrl, loadRoute } = navigationHelpers;

        return {
            restrict: 'E',
            scope: {
                section: '@', // see also: availableSections,
                page: '@',
            },
            templateUrl,

            async link(scope, elem) {
                const config = ConfigFactory.getSync(true);

                // convenience getter
                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                    configurable: true,
                });

                function setupNavigationSections() {
                    // Certain sections are hidden on desktop because they don't actually navigate the user
                    // to another route within settings, but rather have some other functionality, like
                    // launching the support modal or opening a new tab to our FAQ articles.
                    const sectionsToHide = scope.isMobile ? [] : ['faq', 'support', 'switch_program', 'nominations'];
                    const [navSections] = getAvailableNavigationSections($injector, scope.currentUser, sectionsToHide);
                    scope.availableSections = navSections;
                }

                setupBrandNameProperties($injector, scope);
                setupBrandScopeProperties($injector, scope, ['supportsSelectProgram']);
                setupNavigationSections();

                // We need to ensure the documents section is present or removed when appropriate.
                // So we need to detect when this changes and
                // add or remove the documents section as needed.
                scope.$watch('currentUser.fallback_program_type', (oldVal, newVal) => {
                    if (!scope.currentUser) return;
                    if (oldVal !== newVal) setupNavigationSections();
                });

                Object.defineProperty(scope, 'showPreviewProfileStatusMessage', {
                    get() {
                        return scope.section === 'my-profile' && !!scope.currentUser;
                    },
                    configurable: true,
                });

                scope.$watch('section', section => {
                    // Once the user lands on the application, we no longer need to redirect them to the
                    // application in this session
                    if (section === 'application' && scope.currentUser) {
                        scope.currentUser.$$hasBeenRedirectedToApplication = true;
                    }
                });

                scope.dateHelper = dateHelper;
                NavigationHelperMixin.onLink(scope);
                isMobileMixin.onLink(scope);

                // support legacy URLs for awhile
                if (scope.section === 'mba') {
                    scope.section = 'application_status';
                }

                // Setup localization keys
                const translationHelper = new TranslationHelper('settings.settings');
                const editCareerProfileTranslationHelper = new TranslationHelper('careers.edit_career_profile');

                AppHeaderViewModel.setBodyBackground('beige');
                AppHeaderViewModel.showAlternateHomeButton = false;

                scope.$watch('isMobile', val => {
                    // we hide the app header title on this screen
                    if (val) {
                        AppHeaderViewModel.setTitleHTML('');
                        AppHeaderViewModel.showCaret = true;

                        // If the user is visiting this screen on mobile, we should hide the "Settings has moved!" top message
                        // tooltip.
                        ClientStorage.setItem('mobile_top_message_settings_has_moved_hidden', true);
                    }
                    setupNavigationSections();
                });

                // Default title
                SiteMetadata.updateHeaderMetadata();

                const chinaRegionMode = config.chinaRegionMode();

                Object.defineProperty(scope, 'applicationProgramTypeHeader', {
                    get() {
                        // FIXME: as part of https://trello.com/c/V9gtpgF5, read this from a programTypeConfig and not the user
                        return ProgramTypeConfigs[scope.currentUser?.relevantCohort.programType]
                            ?.applicationProgramTypeHeader;
                    },
                });

                scope.changeApplicationProgramTypeHtml = translationHelper.get('change_application_program_type');

                scope.onClickChangeApplicationProgramType = () => {
                    loadRoute('/settings/select-program');
                };

                /** end Application Program Type Header */

                const applicationSubHeaders = {
                    resume_and_links: editCareerProfileTranslationHelper.get(
                        chinaRegionMode ? 'resume_and_links_desc_zh' : 'resume_and_links_desc',
                    ),
                    student_network_settings: editCareerProfileTranslationHelper.get('student_network_settings_desc'),
                    program_preferences: targetBrandConfig(scope.currentUser).programPreferencesSubHeadingLocaleKey
                        ? editCareerProfileTranslationHelper.get(
                              targetBrandConfig(scope.currentUser).programPreferencesSubHeadingLocaleKey,
                          )
                        : '',
                    demographics: editCareerProfileTranslationHelper.get('demographics_desc'),
                    tuition_and_scholarships: editCareerProfileTranslationHelper.get('tuition_and_scholarships_desc'),
                };

                applicationSubHeaders.application_questions = editCareerProfileTranslationHelper.get(
                    'application_questions_sub_header_degree_program',
                );

                const careerProfileSubHeaders = {
                    resume_and_links: editCareerProfileTranslationHelper.get(
                        chinaRegionMode ? 'resume_and_links_desc_zh' : 'resume_and_links_desc',
                    ),
                    student_network_settings: editCareerProfileTranslationHelper.get('student_network_settings_desc'),
                    demographics: editCareerProfileTranslationHelper.get('demographics_desc'),
                };

                //------------------------------------------------------------
                // Steps & Percent Complete handling
                //------------------------------------------------------------

                function setInitialStep() {
                    const defaultCurrentStep = '1';
                    if (!scope.currentUser) {
                        scope.initialStep = defaultCurrentStep;
                        return;
                    }

                    // if page is requested and valid, use that for initialStep
                    if (scope.page && scope.formSteps && scope.formSteps[scope.page - 1]) {
                        scope.initialStep = scope.page;
                    } else {
                        scope.initialStep = defaultCurrentStep;
                    }
                }

                const applicationProfileHeaders = {}; // see scope.getApplicationHeaderText

                function updateSectionHeaderAndSubsectionLocales() {
                    _.forEach(scope.formSteps, step => {
                        applicationProfileHeaders[step.stepName] = editCareerProfileTranslationHelper.get(
                            step.stepName === 'submit_application' ? 'submit_application_header' : step.stepName,
                        );
                    });
                }

                scope.setFormStepsAndSetCompletionPercentage = savedCompletionPercentage => {
                    if (
                        !scope.currentUser?.career_profile ||
                        !scope.currentUser.relevantCohort?.supportsEditingCareerProfile
                    ) {
                        return;
                    }

                    scope.formSteps = EditCareerProfileHelper.isProfile()
                        ? StudentProfileFormConfigs
                        : scope.currentUser.relevantCohort.applicationFormConfigs;

                    scope.formsProgressMap = getFormsProgressMap(scope.currentUser, scope.formSteps);

                    scope.formsCompletePercent =
                        savedCompletionPercentage || getFormsCompletePercent(scope.currentUser, scope.formSteps);

                    // ensure that the section locales are updated as well
                    updateSectionHeaderAndSubsectionLocales();
                };

                // invoke immediately to ensure `formSteps` and `stepsProgressMap` gets
                // set prior to other necessary init tasks (such as `setInitialStep`)
                scope.setFormStepsAndSetCompletionPercentage();
                setInitialStep();

                scope.$on('savedCareerProfile', (_event, payload) => {
                    scope.setFormStepsAndSetCompletionPercentage(payload.savedCompletionPercentage);
                }); // see edit_career_profile_dir.js for where this is emitted

                //-------------------------
                // Navigation / Actions
                //-------------------------

                scope.sectionOnClick = section => {
                    section.onClick();
                    scope.mobileNavExpanded = false;
                };

                scope.gotoEditCareerProfileStep = $index => {
                    scope.$broadcast('gotoFormStep', $index);
                    scope.mobileNavExpanded = false;
                };

                scope.getApplicationHeaderText = () => {
                    if (!scope.applicationProfileActiveStep) {
                        return '';
                    }

                    const { stepName } = scope.applicationProfileActiveStep;

                    return applicationProfileHeaders[stepName];
                };

                scope.getApplicationProfileSubHeaderText = subheaders => {
                    let text;
                    if (subheaders === 'applicationSubHeaders') {
                        text =
                            scope.applicationProfileActiveStep && scope.applicationProfileActiveStep.stepName
                                ? applicationSubHeaders[scope.applicationProfileActiveStep.stepName]
                                : '';
                    } else if (subheaders === 'careerProfileSubHeaders') {
                        text =
                            scope.applicationProfileActiveStep && scope.applicationProfileActiveStep.stepName
                                ? careerProfileSubHeaders[scope.applicationProfileActiveStep.stepName]
                                : '';
                    }
                    $sce.trustAsHtml(text);
                    return text;
                };

                // This sets a viewModel object representing the state of the header, subheader, and checkmark for the
                // application_status header bar.
                scope.setApplicationStatusHeaderViewModel = () => {
                    if (!(scope.currentUser && getAoiRecord(scope.currentUser) && scope.currentUser.relevantCohort)) {
                        return null;
                    }

                    const viewModel = {
                        header: translationHelper.get('application_status'),
                        subheader: '',
                        showCheckmark: false,
                    };

                    const acceptedOrPreAccepted = getHasOfferOrIncludedOrCompleted(scope.currentUser);
                    const tuitionRegistrationHeaderParams = getHeaderParamsForTuitionAndRegistration($injector);

                    // These are defaults.  In most cases where we are showing the registration UI,
                    // we use these values.  But these are changed in a couple of situations below.
                    if (acceptedOrPreAccepted) {
                        viewModel.header = translationHelper.get('congratulations_you_are_in');
                        viewModel.showCheckmark = true;
                    }

                    if (scope.currentUser.isFailed) {
                        viewModel.header = translationHelper.get('graduation_requirements_not_met');
                        viewModel.showCheckmark = false;
                    }

                    // In the case of a pre_accepted/accepted, non-failed user in a cohort
                    // that requires payment, we defer to getTuitionAndRegistrationSection
                    else if (tuitionRegistrationHeaderParams) {
                        Object.assign(viewModel, tuitionRegistrationHeaderParams);
                    }

                    // mba user (there is a schedule, but there is no such thing as registration)
                    else if (
                        getHasPendingAdmissionOfferOrIsNotYetCurrent(scope.currentUser) &&
                        scope.currentUser.relevantCohort.supportsSchedule
                    ) {
                        viewModel.subheader = translationHelper.get('class_begins_on_x', {
                            startDate: dateHelper.formattedUserFacingMonthDayYearLong(
                                scope.currentUser.relevantCohort.startDate,
                                false,
                            ),
                        });
                    }

                    scope.applicationStatusHeaderViewModel = viewModel;
                    return viewModel;
                };

                scope.$watchGroup(
                    [
                        'currentUser.admissionOffers',
                        'currentUser.programInclusions',
                        'currentUser.tuitionContracts',
                        'currentUser.completedStripeCheckoutSession',
                        'currentUser.admissionOffers',
                        'currentUser.programInclusions',
                        'currentUser.tuitionContracts',
                    ],
                    scope.setApplicationStatusHeaderViewModel,
                );

                Object.defineProperty(scope, 'applicationDeadlineReminder', {
                    get() {
                        const applicationDeadline = scope.currentUser?.relevantCohort?.applicationDeadline();
                        if (applicationDeadline) {
                            return translationHelper.get('application_deadline_reminder', {
                                deadline: formattedUserFacingMonthDayLong(applicationDeadline, true),
                            });
                        }
                        return '';
                    },
                });

                const applicationDeadline = scope.currentUser?.relevantCohort?.applicationDeadline();
                scope.showApplicationDeadlineReminder = applicationDeadline
                    ? new Date() > subtractDays(7, applicationDeadline)
                    : false;

                let pageListener;
                let pageListenerInitialized;

                function registerPageListener() {
                    pageListener = scope.$watch(
                        () => $location.search().page,
                        (newVal, oldVal) => {
                            if (!pageListenerInitialized || newVal !== oldVal) {
                                pageListenerInitialized = true;
                                scope.activePageIndex = parseInt($location.search().page, 10) || 1;

                                // application
                                if (_.includes(['application', 'my-profile'], scope.section)) {
                                    const applicationOrProfileSection = _.find(scope.availableSections, {
                                        name: scope.section,
                                    });
                                    scope.applicationProfileActiveStep =
                                        applicationOrProfileSection &&
                                        applicationOrProfileSection.subsections &&
                                        applicationOrProfileSection.subsections[scope.activePageIndex - 1];
                                } else {
                                    scope.applicationProfileActiveStep = undefined;
                                }
                            }
                        },
                    );
                }

                function deregisterPageListener() {
                    if (pageListener) {
                        pageListener();
                        pageListener = undefined;
                    }
                    pageListenerInitialized = false;
                }

                function updateFooterStatus() {
                    scope.showResponsiveFooter = !!$window.CORDOVA;
                    scope.showMainFooter = (!scope.section || scope.section === 'account') && !!$window.CORDOVA;
                }

                scope.$watch('section', section => {
                    updateFooterStatus();

                    if (!_.includes(_.map(scope.availableSections, 'name'), section)) {
                        // if we were linked to the mobile section and this is mobile, expand the nav by default
                        if (scope.isMobile && section === 'mobile') {
                            scope.mobileNavExpanded = true;
                            // force this to "account" so it will match the default account view we are rendering underneath the nav
                            scope.titleLocaleKey = 'account';
                        }
                        // If we were linked to the application_status section and the user either doesn't have an application,
                        // or has a pending application that they can edit, send them to the application section.
                        else if (
                            section === 'application_status' &&
                            (scope.currentUser.canCurrentlySubmitProgramApplication ||
                                scope.currentUser.hasPendingProgramApplication)
                        ) {
                            scope.sectionOnClick(
                                scope.availableSections.find(cfg => cfg.name === NavigationSectionKey.application),
                            );
                        } else if (section === 'select-program') {
                            if (!scope.supportsSelectProgram) {
                                loadRoute('/settings/application');
                                return;
                            }

                            scope.selectProgramConfig = selectProgramConfig();

                            // This is a route that is rendered within settings UI but is never visible as a nav item
                            loadRoute('/settings/select-program');
                            scope.titleLocaleKey = 'select-program';
                        } else if (
                            ['alumni-select-program', 'alumni-select-msba', 'alumni-select-msse'].includes(section)
                        ) {
                            scope.selectProgramConfig = selectProgramConfig();

                            const alumnus = scope.currentUser.programInclusions.some(
                                pi => hasGraduated(pi) && Cohort.isDegreeProgram(pi.programType),
                            );
                            if (!alumnus || !config.enableAlumniSelectProgram()) {
                                scope.section = 'select-program';
                                return;
                            }

                            switch (section) {
                                case 'alumni-select-program':
                                    scope.preapprovals = ['msba', 'msse'];
                                    break;
                                case 'alumni-select-msba':
                                    scope.preapprovals = ['msba'];
                                    break;
                                case 'alumni-select-msse':
                                    scope.preapprovals = ['msse'];
                                    break;
                                default:
                                    scope.preapprovals = null;
                            }

                            // This is a route that is rendered within settings UI but is never visible as a nav item
                            loadRoute(`/settings/${section}`);
                            scope.titleLocaleKey = 'select-preapproved-program';
                        } else {
                            scope.sectionOnClick(
                                scope.availableSections.find(cfg => cfg.name === NavigationSectionKey.account),
                            );
                        }

                        return; // terminate early; the rest of this watcher is about correctly rendering the current section
                    }

                    // if the page supports page navigation, re-enable the page listeners
                    if (_.includes(['application', 'my-profile'], scope.section)) {
                        registerPageListener();
                    } else {
                        deregisterPageListener();
                        $location.search('page', null);
                    }

                    // Log an event if they are navigating to the MBA application
                    if (section === 'application') {
                        EditCareerProfileHelper.logEventForApplication('start-application');
                    }

                    scope.titleLocaleKey = section;
                });

                scope.$watch('mobileNavExpanded', () => {
                    const navEl = elem.find('.responsive-nav').get(0);
                    scope.responsiveNavScrolling = navEl.offsetHeight && navEl.scrollHeight > navEl.offsetHeight;
                });

                Object.defineProperty(scope, 'sectionNavigationTitle', {
                    get() {
                        // build up the section navigation title here
                        const parts = [translationHelper.get('menu_title')];

                        const currentSection =
                            _.find(scope.availableSections, {
                                name: scope.section,
                            }) ||
                            _.find(scope.availableSections, {
                                name: 'account',
                            });

                        parts.push(translationHelper.get(currentSection.name, { brandName: scope.brandNameShort }));

                        // TODO: support any sub-sections here, e.g.: MBA application form

                        return parts.join(' – ');
                    },
                });

                scope.launchProgramGuide = () => {
                    const aoiRecord = getAoiRecord(scope.currentUser);
                    if (!aoiRecord) return;

                    $window.open(aoiRecord.cohortProgramGuideUrl, $window.CORDOVA ? '_system' : '_blank');
                };

                scope.openCongratsModal = () => {
                    // cert-congrats-modal is a react2angular component defined in
                    // sidebarBoxConfigs.ts
                    DialogModal.alert({
                        content: `
                            <cert-congrats-modal
                                current-user="currentUser"
                                program-type-configs="programTypeConfigs"
                                load-url="loadUrl"
                                filtered-eligibility-bundle="filteredEligibilityBundle"
                            ></cert-congrats-modal>`,
                        size: 'fullscreen',
                        hideCloseButton: false,
                        closeOnClick: false,
                        scope: {
                            programTypeConfigs: keyBy('key')(Cohort.programTypes),
                            loadUrl,
                            filteredEligibilityBundle: getFilteredExecEdEligibilityBundle(
                                scope.currentUser.execEdEligibilityBundle,
                                scope.currentUser.admissionOffers,
                                getRelevantCohorts(scope.currentUser),
                            ),
                        },
                        classes: ['exec-ed-cert-congrats-modal'],
                    });
                };

                scope.$on('$destroy', () => {
                    $location.search('page', null);
                    AppHeaderViewModel.toggleVisibility(true);
                });

                // Handle a
                scope.handleAffordableTuitionClick = () => {
                    const programTypeConfig = ProgramTypeConfigs[scope.currentUser.relevantCohort.programType];
                    DialogModal.alert({
                        content: `
                            <div class="center">
                                ${translationHelper.get('affordable_tuition_modal', {
                                    standardMonthlyPayment: programTypeConfig.standardMonthlyPayment,
                                })}
                            </div>
                        `,
                    });
                };
            },
        };
    },
]);
