import { Route, redirect } from 'react-router-dom';
import { route, stringType } from 'react-router-typesafe-routes/dom';
import { type FrontRoyalRootScope } from 'FrontRoyalAngular';
import { getProgramInclusion } from 'Users';
import { angularInjectorProvider } from 'Injector';
import { ProtectedRoute } from 'ProtectedRoute';

export const ROUTES = {
    RESOURCES: route(
        'resources',
        {},
        {
            ADMISSIONS: route('admissions'),
            ACADEMIC_AND_PROGRAM_SUPPORT: route('academic-and-program-support'),
            CAREER_SERVICES: route('career-services'),
            LIBRARY_SERVICES: route('library-services'),
            STUDENT_EVENTS: route('student-events'),
            SEARCH: route('search', {
                searchParams: { query: stringType('') },
            }),
        },
    ),
};

async function getLazyResourcesComponent(
    component:
        | 'Layout'
        | 'Admissions'
        | 'AcademicAndProgramSupport'
        | 'CareerServices'
        | 'LibraryServices'
        | 'StudentEvents'
        | 'SearchResults',
) {
    const components = await import(/* webpackPrefetch: true */ './Resources');
    const ResourcesComponent = components[component];
    if (component === 'Layout') {
        return {
            Component: () => (
                <ProtectedRoute>
                    <ResourcesComponent />
                </ProtectedRoute>
            ),
        };
    }
    return { Component: ResourcesComponent };
}

export function getResourcesRoutes() {
    return (
        <Route path={ROUTES.RESOURCES.relativePath} lazy={() => getLazyResourcesComponent('Layout')}>
            <Route
                index
                loader={() => {
                    const { currentUser = null } = angularInjectorProvider.get<FrontRoyalRootScope>('$rootScope');
                    const programInclusion = getProgramInclusion(currentUser);
                    const showAdmissionsTab = !programInclusion;
                    return redirect(
                        showAdmissionsTab
                            ? ROUTES.RESOURCES.ADMISSIONS.path
                            : ROUTES.RESOURCES.ACADEMIC_AND_PROGRAM_SUPPORT.path,
                    );
                }}
            />
            <Route
                path={ROUTES.RESOURCES.$.ADMISSIONS.relativePath}
                lazy={() => getLazyResourcesComponent('Admissions')}
            />
            <Route
                path={ROUTES.RESOURCES.$.ACADEMIC_AND_PROGRAM_SUPPORT.relativePath}
                lazy={() => getLazyResourcesComponent('AcademicAndProgramSupport')}
            />
            <Route
                path={ROUTES.RESOURCES.$.CAREER_SERVICES.relativePath}
                lazy={() => getLazyResourcesComponent('CareerServices')}
            />
            <Route
                path={ROUTES.RESOURCES.$.LIBRARY_SERVICES.relativePath}
                lazy={() => getLazyResourcesComponent('LibraryServices')}
            />
            <Route
                path={ROUTES.RESOURCES.$.STUDENT_EVENTS.relativePath}
                lazy={() => getLazyResourcesComponent('StudentEvents')}
            />
            <Route
                path={ROUTES.RESOURCES.$.SEARCH.relativePath}
                lazy={() => getLazyResourcesComponent('SearchResults')}
            />
        </Route>
    );
}
