import angularModule from 'Settings/angularModule/scripts/settings_module';
import { setupBrandNameProperties, targetBrandConfig } from 'AppBranding';
import { getProgramInclusion, getHasOfferOrIncludedOrCompleted, getIsCurrentOrHasCompletedActiveProgram } from 'Users';
import template from 'Settings/angularModule/views/edit_account.html';
import cacheAngularTemplate from 'cacheAngularTemplate';
import { identifyUser } from 'Authentication';
import { BioSigMixin } from 'BioSig';
import { isIncluded } from 'ProgramInclusion';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('editAccount', [
    '$injector',

    function factory($injector) {
        const ConfigFactory = $injector.get('ConfigFactory');
        const ngDeviseTokenAuthClient = $injector.get('ngDeviseTokenAuthClient');
        const $rootScope = $injector.get('$rootScope');
        const ngToast = $injector.get('ngToast');
        const isMobileMixin = $injector.get('isMobileMixin');
        const TranslationHelper = $injector.get('TranslationHelper');
        const scrollHelper = $injector.get('scrollHelper');
        const NavigationHelperMixin = $injector.get('Navigation.NavigationHelperMixin');
        const $timeout = $injector.get('$timeout');
        const safeApply = $injector.get('safeApply');
        const SignOutHelper = $injector.get('SignOutHelper');
        const DialogModal = $injector.get('DialogModal');

        return {
            restrict: 'E',
            templateUrl,
            scope: {},
            link(scope) {
                //-------------------------
                // Initialization
                //-------------------------

                NavigationHelperMixin.onLink(scope);
                BioSigMixin.onLink(scope, $injector);
                setupBrandNameProperties($injector, scope);

                // Setup localization keys
                const translationHelper = new TranslationHelper('settings.edit_account');

                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                Object.defineProperty(scope, 'brandSupportEmail', {
                    get() {
                        return targetBrandConfig(scope.currentUser).emailAddressForUsername('support');
                    },
                });

                Object.defineProperty(scope, 'canDeleteOwnAccount', {
                    get() {
                        return scope.currentUser?.can_delete_own_account;
                    },
                });

                //-------------------------
                // Navigation / Actions
                //-------------------------

                scope.preventSubmit = false;

                scope.studentInfo = {
                    forwarding_address: scope.currentUser.studentEmailAddressForLastActiveAoiRecord?.forwarding_address,
                };

                scope.form = {
                    name: scope.currentUser.name,
                    nickname: scope.currentUser.nickname,
                    email: scope.currentUser.email,
                    phone: scope.currentUser.phone,
                };

                scope.updateAccount = () => {
                    scope.preventSubmit = true;

                    ConfigFactory.getConfig()
                        .then(config => {
                            if (config.disableDevise()) return ngDeviseTokenAuthClient.updateAccount(scope.form);

                            scope.currentUser.name = scope.form.name;
                            scope.currentUser.nickname = scope.form.nickname;
                            scope.currentUser.email = scope.form.email;
                            return scope.currentUser.save();
                        })
                        .then(
                            () => {
                                scope.preventSubmit = false;
                                ngToast.create({
                                    content: translationHelper.get('profile_update_success'),
                                    className: 'success',
                                });

                                // see https://trello.com/c/xa4zY5EU/260-0-5-iguana-provisional-changes
                                // for maybe a nicer way of handling this
                                // https://trello.com/c/sbNR92fp - No longer necessary after Devise switchover
                                scope.currentUser.name = scope.form.name;
                                scope.currentUser.nickname = scope.form.nickname;
                                scope.currentUser.email = scope.form.email;
                                scope.currentUser.phone = scope.form.phone;

                                // re-identify
                                identifyUser($rootScope.currentUser);
                            },
                            response => {
                                let message = translationHelper.get('profile_update_failure');
                                if (response?.data?.errors?.full_messages?.length > 0) {
                                    message = response.data.errors.full_messages[0];
                                }
                                scope.preventSubmit = false;
                                ngToast.create({
                                    content: message,
                                    className: 'danger',
                                });
                            },
                        )
                        .finally(() => {
                            safeApply($rootScope);
                        });
                };

                scope.deleteAccount = () => {
                    const onConfirm = () => {
                        scope.preventSubmit = true;
                        scope.currentUser
                            .deleteAccount()
                            .then(() => {
                                ngToast.create({
                                    content: translationHelper.get('account_scheduled_for_deletion'),
                                    className: 'success',
                                });
                                // delay sign-out long enough to read the success toast
                                $timeout(() => {
                                    SignOutHelper.signOut();
                                }, 1000);
                            })
                            .catch(() => {
                                scope.preventSubmit = false;
                            });
                    };
                    DialogModal.confirm({
                        title: translationHelper.get('confirm_account_deletion_title'),
                        text: translationHelper.get('confirm_account_deletion'),
                        confirmButtonText: translationHelper.get('delete').toLocaleUpperCase(),
                        confirmButtonClass: 'delete',
                        confirmCallback: onConfirm,
                    });
                };

                scope.updateStudentEmailForwardingAddress = () => {
                    scope.preventSubmit = true;

                    const studentEmailAddress = scope.currentUser.studentEmailAddressForLastActiveAoiRecord;
                    studentEmailAddress.forwarding_address = scope.studentInfo.forwarding_address;

                    scope.currentUser.save().then(() => {
                        scope.preventSubmit = false;
                        ngToast.create({
                            content: translationHelper.get('profile_update_success'),
                            className: 'success',
                        });
                    });
                };

                scope.openChangePassword = () => {
                    scope.showChangePasswordForm = true;
                    scope.showProfileForm = false;
                    scrollHelper.scrollToTop();
                };

                scope.closeChangePassword = () => {
                    scope.showChangePasswordForm = false;
                    scope.showProfileForm = true;
                    scrollHelper.scrollToTop();
                };

                //-------------------------
                // Display Helpers
                //-------------------------

                isMobileMixin.onLink(scope);

                const providersAllowingEmailUpdates = [
                    'email',
                    'facebook',
                    'google_oauth2',
                    'apple_quantic',
                    'apple_smartly',
                    'wechat_official_account',
                    'wechat_native',
                    'wechat_web',
                    'wechat',
                ];

                function updateViewHelpers() {
                    // prevent RTE on logout
                    if (!scope.currentUser) {
                        return;
                    }

                    scope.allowEmailUpdate =
                        providersAllowingEmailUpdates.includes(scope.currentUser.provider) &&
                        !scope.currentUser.hasSamlProvider;

                    // conditional display based on user type
                    scope.showProfileForm = true;
                    scope.showChangePasswordForm = !scope.isMobile;
                    scope.userCanHavePassword = !scope.currentUser.hasSamlProvider;
                    scope.showDeferInfo =
                        isIncluded(getProgramInclusion(scope.currentUser)) &&
                        scope.currentUser.relevantCohort &&
                        scope.currentUser.relevantCohort.supportsSchedule;
                    scope.showProctoringSection =
                        // we allow admins to launch into BioSig for testing purposes
                        !!(scope.currentUser.hasAdminAccess && ConfigFactory.getSync().biosigEnabled()) ||
                        // students can launch if they are actively in a cohort that requires it
                        !!(
                            isIncluded(getProgramInclusion(scope.currentUser)) &&
                            scope.currentUser.relevantCohort?.requiresProctoring &&
                            ConfigFactory.getSync().biosigEnabled()
                        );

                    scope.showStudentIdSection =
                        !!scope.currentUser.student_id && getHasOfferOrIncludedOrCompleted(scope.currentUser);

                    // We've architected student email addresses on the server in such a way that allows the
                    // user to have multiple student email addresses if we ever transition to a world where
                    // the user can be admitted into more than one program at a time. However, we're not in
                    // that world yet and currently only Quantic programs support student email addresses.
                    // So, until we move to that world, the client will follow a similar trend to what we
                    // do elsewhere in the app and base the UI on the user's lastActiveAoiRecord.
                    scope.showStudentEmailSection =
                        !!scope.currentUser.studentEmailAddressForLastActiveAoiRecord?.hasMailgunRoute &&
                        getIsCurrentOrHasCompletedActiveProgram(scope.currentUser);
                }

                // easier to test if this is in a watch, even though it will not change
                scope.$watch('currentUser.provider', updateViewHelpers);
                updateViewHelpers();

                scope.$watch('isMobile', (newVal, oldVal) => {
                    if (newVal && !oldVal) {
                        // hide the change password if we're transitioning to mobile
                        scope.showChangePasswordForm = false;
                    } else if (!newVal && oldVal) {
                        // otherwise show everything if we're transitioning to desktop
                        scope.showChangePasswordForm = true;
                        scope.showProfileForm = true;
                    }

                    // On mobile, the submit button for the password form says "Save Changes",
                    // On desktop, since there is already a button that says "Save Changes",
                    // it is "Change Password".
                    if (newVal) {
                        scope.changePasswordSubmitText = translationHelper.get('save_changes');
                    } else {
                        scope.changePasswordSubmitText = translationHelper.get('change_password');
                    }
                });

                scope.hasClipboardAccess = navigator.clipboard && window.isSecureContext;
                scope.copyMessage = translationHelper.get('copy');

                scope.copyToClipboard = async val => {
                    await navigator.clipboard.writeText(val);

                    scope.copyMessage = translationHelper.get('copied');

                    safeApply(scope);

                    $timeout(() => {
                        scope.copyMessage = translationHelper.get('copy');
                    }, 2000);
                };
            },
        };
    },
]);
