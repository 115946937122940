import { targetBrandConfig } from 'AppBranding';
import { type FrontRoyalRootScope } from 'FrontRoyalAngular';
import { type TranslationHelperClass } from 'Translation';
import { ScholarshipCategory, type ScholarshipConfig } from './Scholarship.types';

// add any additional supported suffixes here
type LabelSuffix = 'application';

// These take effect in 53+ cohorts
// eslint-disable-next-line max-lines-per-function
const scholarshipConfigs = (
    $injector: ng.auto.IInjectorService,
    labelSuffix?: LabelSuffix,
): Record<ScholarshipCategory, ScholarshipConfig> => {
    const currentUser = $injector.get<FrontRoyalRootScope>('$rootScope').currentUser;
    const TranslationHelper = $injector.get<TranslationHelperClass>('TranslationHelper');
    const tsTranslationHelper = new TranslationHelper('careers.edit_career_profile.tuition_and_scholarships_form');
    const slTranslationHelper = new TranslationHelper('scholarship_labels');
    const userBrandConfig = targetBrandConfig(currentUser!);

    return {
        merit: {
            category: ScholarshipCategory.merit,
            label: slTranslationHelper.get(
                `${userBrandConfig.scholarshipLabelsMap.merit}${labelSuffix ? `_${labelSuffix}` : ''}`,
            ),
            description: tsTranslationHelper.get('merit_desc'),
            scholarshipBasis: 'merit',
        },
        alumni: {
            category: ScholarshipCategory.alumni,
            label: slTranslationHelper.get(
                `${userBrandConfig.scholarshipLabelsMap.alumni}${labelSuffix ? `_${labelSuffix}` : ''}`,
            ),
            description: tsTranslationHelper.get('alumni_desc'),
            scholarshipBasis: 'merit',
        },
        impact: {
            category: ScholarshipCategory.impact,
            label: slTranslationHelper.get(
                `${userBrandConfig.scholarshipLabelsMap.impact}${labelSuffix ? `_${labelSuffix}` : ''}`,
            ),
            description: tsTranslationHelper.get('impact_desc'),
            scholarshipBasis: 'merit',
        },
        employer_partner: {
            category: ScholarshipCategory.employer_partner,
            label: slTranslationHelper.get(
                `${userBrandConfig.scholarshipLabelsMap.employer_partner}${labelSuffix ? `_${labelSuffix}` : ''}`,
            ),
            description: tsTranslationHelper.get('employer_partner_desc'),
            scholarshipBasis: 'employer',
        },
        recruitment: {
            category: ScholarshipCategory.recruitment,
            label: slTranslationHelper.get(
                `${userBrandConfig.scholarshipLabelsMap.recruitment}${labelSuffix ? `_${labelSuffix}` : ''}`,
            ),
            description: tsTranslationHelper.get('recruitment_desc'),
            scholarshipBasis: 'merit',
        },
        need: {
            category: ScholarshipCategory.need,
            label: slTranslationHelper.get(
                `${userBrandConfig.scholarshipLabelsMap.need}${labelSuffix ? `_${labelSuffix}` : ''}`,
            ),
            description: tsTranslationHelper.get('need_desc', { instNameShort: userBrandConfig.brandNameShort }),
            scholarshipBasis: 'need',
        },
    };
};

export default scholarshipConfigs;
