import angularModule from 'Settings/angularModule/scripts/settings_module';
import template from 'Settings/angularModule/views/tuition_and_registration/cohort_registration_plan_selection.html';
import cacheAngularTemplate from 'cacheAngularTemplate';
import { selectablePaymentSituationForTuitionPlanFrequency } from 'PaymentSituation';
import { getAdmissionOffer, getProgramApplication } from 'Users';
import { getProduct } from 'Stripe';
import { isPartialScholarship } from 'Scholarship';
import { TuitionPlanFrequency } from 'TuitionPlan';
import { formatCurrencyForDisplay } from 'Currency';
import { getProgramConfigValue } from 'Program';
import { getSplitTestConfig } from 'SplitTesting';
import { statsigClientProvider } from 'StatsigClientProvider';
import { targetBrandConfig } from 'AppBranding';
import { getDefaultSelectedPaymentOption, getPaymentConfig } from './getPaymentConfig';
import {
    attachDeferralModalFn,
    attachOpenStripeCheckoutSessionFn,
    attachRegisterButtonWatchFn,
    attachRegisterEnabledWatchFn,
    attachPaymentConfigStateWatchFn,
    attachCohortDatesFn,
} from './CohortRegistrationDirHelpers';
import { PaymentTypeOption, PaymentView } from './TuitionAndRegistration.types';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('cohortRegistrationPlanSelection', [
    '$injector',

    function factory($injector) {
        const $rootScope = $injector.get('$rootScope');
        const DialogModal = $injector.get('DialogModal');
        const TranslationHelper = $injector.get('TranslationHelper');
        const ErrorLogService = $injector.get('ErrorLogService');
        const ConfigFactory = $injector.get('ConfigFactory');
        const NavigationHelperMixin = $injector.get('Navigation.NavigationHelperMixin');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                // This is called from inside the smallprint locale
                launchProgramGuide: '&',
                section: '<',
            },
            link(scope) {
                //-------------------------
                // Initialization
                //-------------------------
                NavigationHelperMixin.onLink(scope);
                const translationHelper = new TranslationHelper('settings.tuition_and_registration');

                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                const impactGlobalScholarshipTestConfig = getSplitTestConfig(
                    'impactGlobalScholarshipTest',
                    scope.currentUser,
                );

                scope.creditCardPaymentFeeRate = ConfigFactory.getSync().creditCardPaymentFeeRate();
                scope.supportsBuyNowPayLater = getProgramConfigValue(
                    scope.currentUser.relevantCohort.programType,
                    'supportsBuyNowPayLater',
                );

                // A user should never see this page if their active AOI record isn't an AdmissionOffer.
                scope.admissionOffer = getAdmissionOffer(scope.currentUser);

                // While it is technically possible for an admission offer to have multiple cohort section offers
                // (in the sense that the data model supports it), it should not be possible for a user to be offered
                // admission with multiple cohort section offers going forward. We can safely look at the first element
                // in the array here since this UI only applies to folks who are offered admission.
                scope.cohortSectionOffer = scope.admissionOffer?.cohortSectionOffers[0];
                scope.numOfferedScholarships = scope.cohortSectionOffer?.offeredScholarships?.length ?? 0;

                // It shouldn't be possible to get here without a CohortSectionOffer or an AdmissionOffer.
                // If we do, we want to know about it. If this error is logged, the UI is definitely broken for this user.
                if (!scope.cohortSectionOffer) {
                    ErrorLogService.notifyInProd(
                        'Trying to render cohortRegistrationPlanSelection UI without a CohortSectionOffer',
                        undefined,
                        {
                            userId: scope.currentUser.id,
                        },
                    );
                    $rootScope.goHome();
                }

                scope.programInclusionProxy = {
                    shareable_with_classmates: true,
                };

                scope.peerNomination = getProgramApplication(scope.currentUser)?.peerNomination;
                if (scope.peerNomination) {
                    scope.notifyNominatorProxy = { value: true };
                }

                scope.selectedPaymentTypeProxy = { value: null };

                scope.PaymentView = PaymentView; // referenced in template
                scope.activePaymentView = PaymentView.select_plan;

                scope.hasPartialScholarship = isPartialScholarship(
                    scope.cohortSectionOffer?.cumulativeOfferedScholarship,
                );

                scope.totalScholarshipForDisplay = formatCurrencyForDisplay(
                    scope.cohortSectionOffer?.cumulativeOfferedScholarship?.amount,
                );

                const onetimePaymentSituation = selectablePaymentSituationForTuitionPlanFrequency(
                    scope.cohortSectionOffer?.selectablePaymentSituations,
                    TuitionPlanFrequency.Once,
                );
                const annualPaymentSituation = selectablePaymentSituationForTuitionPlanFrequency(
                    scope.cohortSectionOffer?.selectablePaymentSituations,
                    TuitionPlanFrequency.Annual,
                );
                const monthlyPaymentSituation = impactGlobalScholarshipTestConfig.showMonthlyPlan
                    ? selectablePaymentSituationForTuitionPlanFrequency(
                          scope.cohortSectionOffer?.selectablePaymentSituations,
                          TuitionPlanFrequency.Monthly,
                      )
                    : null;
                const defaultPaymentSituation =
                    onetimePaymentSituation || annualPaymentSituation || monthlyPaymentSituation;

                scope.planSelectionProxy = {
                    selectedTuitionPlanId: defaultPaymentSituation?.tuitionPlan?.id,
                };

                scope.selectablePaymentSituations = [
                    onetimePaymentSituation,
                    annualPaymentSituation,
                    monthlyPaymentSituation,
                ].filter(Boolean);

                //-------------------------
                // Payment Config
                //-------------------------

                scope.$watchGroup(
                    [
                        'paymentConfigState',
                        'planSelectionProxy.selectedTuitionPlanId',
                        'creditCardPaymentFeeRate',
                        'supportsBuyNowPayLater',
                        'currentUser.country',
                        'currentUser.state',
                    ],
                    ([
                        paymentConfigState,
                        selectedTuitionPlanId,
                        creditCardPaymentFeeRate,
                        supportsBuyNowPayLater,
                        country,
                        state,
                    ]) => {
                        const selectedPaymentSituation = scope.selectablePaymentSituations.find(
                            situation => situation.tuitionPlan.id === selectedTuitionPlanId,
                        );

                        scope.paymentConfig = getPaymentConfig({
                            paymentConfigState,
                            selectedPaymentSituation,
                            creditCardPaymentFeeRate,
                            section: scope.section,
                            supportsBuyNowPayLater,
                            country,
                            state,
                        });

                        if (!scope.paymentConfig) return;

                        const currentSelectedTypeIsAvailable = scope.paymentConfig
                            .availablePaymentTypeOptions()
                            .includes(scope.selectedPaymentTypeProxy.value);

                        // Preserve the current payment type selection if the type is still available per the new PaymentConfig
                        scope.selectedPaymentTypeProxy.value = currentSelectedTypeIsAvailable
                            ? scope.selectedPaymentTypeProxy.value
                            : getDefaultSelectedPaymentOption(scope.paymentConfig);
                    },
                );

                //-------------------------
                // Registration helpers
                //-------------------------

                attachDeferralModalFn(scope, $injector, scope.cohortSectionOffer?.cumulativeOfferedScholarship);
                attachOpenStripeCheckoutSessionFn(scope, $injector);
                attachRegisterButtonWatchFn(scope);
                attachRegisterEnabledWatchFn(scope);
                attachPaymentConfigStateWatchFn(scope);
                attachCohortDatesFn(scope);

                scope.registerForPreviousCohortProxy = {
                    value: !!scope.showFastTrackRegistration,
                };

                scope.showFundingEmail = false;
                statsigClientProvider.waitForGate('show_funding_email_on_registration_screen').then(result => {
                    scope.showFundingEmail = result;
                });

                scope.$watch('currentUser', currentUser => {
                    if (!currentUser) return;

                    const { fundingEmail } = targetBrandConfig(currentUser);

                    if (!fundingEmail) return;

                    scope.fundingEmail = fundingEmail;
                });

                //-------------------------
                // Locale Keys
                //-------------------------

                if (scope.hasPartialScholarship) {
                    scope.narrativeKey = 'settings.tuition_and_registration.narrative_partial_scholarship';
                    scope.graphicTitleKey = 'settings.tuition_and_registration.graphic_partial_scholarship_title';
                    scope.graphicSubtitleKey = 'settings.tuition_and_registration.graphic_partial_scholarship_subtitle';
                    scope.tuitionDetailsLabelKey = 'settings.tuition_and_registration.tuition_and_scholarship_details';
                } else {
                    scope.narrativeKey = 'settings.tuition_and_registration.narrative_no_scholarship';
                    scope.graphicTitleKey = 'settings.tuition_and_registration.graphic_no_scholarship_title';
                    scope.graphicSubtitleKey = 'settings.tuition_and_registration.graphic_no_scholarship_subtitle';
                    scope.tuitionDetailsLabelKey = 'settings.tuition_and_registration.tuition_details';
                }

                scope.planSelectionLabelKey = 'settings.tuition_and_registration.choose_your_plan';

                scope.smallPrintKey = () => {
                    let key = scope.hasPartialScholarship ? 'smallprint_scholarship' : 'smallprint';

                    if (!scope.reapplicationDate) {
                        key += '_no_reapplication_date';
                    }

                    return `settings.tuition_and_registration.${key}`;
                };

                scope.upfrontPaymentTestConfig = getSplitTestConfig(
                    'upfrontPaymentRegistrationTest',
                    scope.currentUser,
                );
                scope.planDetailsKey = frequency =>
                    `${scope.upfrontPaymentTestConfig.tuitionPlanDetailsLocale}_${frequency}`;

                scope.formattedBaseTuition = paymentSituation =>
                    formatCurrencyForDisplay(paymentSituation.paymentInformation.baseTuition);
                //-------------------------
                // Checkout Session handling
                //-------------------------

                scope.handleRegisterButtonClick = () => {
                    const shouldOpenBankDetailsModal =
                        scope.activePaymentView === PaymentView.select_payment &&
                        scope.selectedPaymentTypeProxy.value === PaymentTypeOption.wire_transfer;

                    const shouldNavigateToSelectPayment =
                        scope.activePaymentView !== PaymentView.select_payment &&
                        scope.paymentConfig.availablePaymentTypeOptions().length > 1 &&
                        !scope.registerRequiresRedirectToBrowser;

                    if (shouldOpenBankDetailsModal) {
                        // do not take to Stripe, just provide wire transfer instructions
                        scope.openBankDetailsModal();
                        return;
                    }

                    if (shouldNavigateToSelectPayment) {
                        scope.activePaymentView = PaymentView.select_payment;
                        return;
                    }

                    // This is side effectful - it is guaranteed to navigate the user away from this page.
                    // If they're able to register in this environment, we'll send them to a Stripe Checkout Session.
                    // If they're unable to register in this environment (Cordova), we'll send them to a system browser.
                    scope.openStripeCheckoutSessionOrBrowser(scope, $injector, {
                        admission_offer_id: scope.admissionOffer.id,
                        notify_nominator: scope.notifyNominatorProxy?.value,
                        payment_type: {
                            [PaymentTypeOption.us_bank_account]: 'ach',
                            [PaymentTypeOption.card]: 'card',
                            [PaymentTypeOption.buy_now_pay_later]: 'bnpl',
                        }[scope.selectedPaymentTypeProxy.value],
                        product_id: getProduct(scope.admissionOffer.programType).id,
                        register_for_previous_cohort: scope.registerForPreviousCohortProxy.value,
                        selected_tuition_plan_id: scope.planSelectionProxy.selectedTuitionPlanId,
                        shareable_with_classmates: scope.programInclusionProxy.shareable_with_classmates,
                    });
                };

                //-------------------------
                // Button handlers
                //-------------------------

                scope.openMailToLink = () => {
                    const subjectString = 'Request for wire transfer bank details';
                    const bodyString =
                        'Hello, can you please pass along the details to initiate a bank/wire transfer for my Quantic tuition? Thank you!';

                    window.open(
                        `mailto:billing@quantic.edu?subject=${encodeURI(subjectString)}&body=${encodeURI(bodyString)}`,
                    );
                };

                scope.backToPlanSelection = () => {
                    scope.activePaymentView = PaymentView.select_plan;
                };

                scope.openOptionsModal = () => {
                    let content = '';

                    if (onetimePaymentSituation) {
                        let oneTimePaymentTextKey = 'make_single_payment';
                        if (scope.hasPartialScholarship) {
                            oneTimePaymentTextKey += '_partial_scholarship';
                        }
                        const oneTimePaymentText = translationHelper.get(oneTimePaymentTextKey, {
                            additionalSavings: onetimePaymentSituation.paymentInformation.formattedNetDiscountAmount,
                        });
                        content += `<h3 translate-once="settings.tuition_and_registration.upfront_option"></h3>\n<p>${oneTimePaymentText}</p>\n`;
                    }

                    if (annualPaymentSituation) {
                        let annualPaymentTextKey = 'make_two_payments';
                        if (scope.hasPartialScholarship) {
                            annualPaymentTextKey += '_partial_scholarship';
                        }
                        const annualPaymentText = translationHelper.get(annualPaymentTextKey, {
                            additionalSavings: annualPaymentSituation.paymentInformation.formattedNetDiscountAmount,
                        });
                        content += `<h3 translate-once="settings.tuition_and_registration.annual_option"></h3>\n<p>${annualPaymentText}</p>\n`;
                    }

                    if (monthlyPaymentSituation) {
                        const monthlyPaymentText = translationHelper.get('make_monthly_payments', {
                            numIntervals: monthlyPaymentSituation.tuitionPlan.numIntervals,
                        });
                        content += `<h3 translate-once="settings.tuition_and_registration.monthly_option"></h3>\n<p>${monthlyPaymentText}</p>\n`;
                    }

                    const refundPolicyText = translationHelper.get('refund_policy');
                    content += `<p class="refund-policy">${refundPolicyText}</p>\n`;

                    DialogModal.alert({
                        title: translationHelper.get('payment_options'),
                        content,
                        classes: ['emba-billing-options', 'billing-options'],
                        scope: {
                            loadFaqUrl: NavigationHelperMixin.loadFaqUrl,
                        },
                    });
                };

                scope.openBankDetailsModal = () => {
                    // Some copy is untranslated because the user needs the exact bank data
                    const content = `
                        <p>${translationHelper.get(
                            `bank_details_intro${scope.selectablePaymentSituations.length === 1 ? '_1_pay_only' : ''}`,
                        )}</p>
                        <dl>
                            <dt>${translationHelper.get('our_bank_heading').toLocaleUpperCase()}</dt>
                            <dd>Bridge Bank, a Division of Western Alliance<br />55 Almaden Blvd, San Jose, CA 95113<br />USA</dd>
                            <dt>${translationHelper.get('our_account_name_heading').toLocaleUpperCase()}</dt>
                            <dd>Quantic Holdings Inc dba Quantic School of Business and Technology</dd>
                            <dt>${translationHelper.get('our_account_number_heading').toLocaleUpperCase()}</dt>
                            <dd>8593474229</dd>
                            <dt>${translationHelper.get('for_us_wires_heading').toLocaleUpperCase()}</dt>
                            <dd>${translationHelper.get('for_us_wires_content', { transitNumber: '121143260' })}</dd>
                            <dt>${translationHelper.get('for_international_wires_heading').toLocaleUpperCase()}</dt>
                            <dd>SWIFT ID: BBFXUS6S</dd>
                            <dt>
                                ${translationHelper
                                    .get('quantic_mailing_address_heading', { institutionName: 'Quantic' })
                                    .toLocaleUpperCase()}
                            </dt>
                            <dd>712 H Street NE Suite 1802<br />Washington, DC 20002<br />USA</dd>
                        </dl>
                        <p>${translationHelper.get('identifier')}</p>
                        <p>${translationHelper.get('contact_billing_with_questions', {
                            billingEmail: scope.currentUser.relevantCohort.supportEmailAddress('billing'),
                        })}</p>
                    `;
                    DialogModal.alert({
                        title: translationHelper.get('wiring_funds'),
                        content,
                        classes: ['bank-details'],
                    });
                };
            },
        };
    },
]);
