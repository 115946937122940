/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    type QueryActionCreatorResult,
    type MutationActionCreatorResult,
    type EndpointDefinitions,
    type BaseQueryFn,
    type FetchArgs,
    type FetchBaseQueryError,
} from '@reduxjs/toolkit/query';
import { type AnyFunction } from '@Types';
import { storeProvider } from './storeProvider';
import { type ApiWithHooks } from './ReduxHelpers.types';

async function sendRequest<ResultType>({ send }: { send: AnyFunction; endpointName: string }): Promise<ResultType> {
    const dispatch = storeProvider.dispatch;
    const request = dispatch(send()) as MutationActionCreatorResult<any> | QueryActionCreatorResult<any>;

    const result = await request;

    // unsubscribe only exists on the result of dispatching queries
    if ('unsubscribe' in request) {
        request.unsubscribe();
    }
    // reset only exists on the result of dispatching mutations
    if ('reset' in request) {
        request.reset();
    }

    // We always expect result.error to be defined here, but just in case, raise the full result
    if (('isError' in result && result.isError) || result.error) throw result.error || result;

    return result.data as ResultType;
}

export function createMakeRequestFunction<
    BaseQuery extends BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>,
    Definitions extends EndpointDefinitions,
    Path extends string,
    TagTypes extends string,
>(api: ApiWithHooks<BaseQuery, Definitions, Path, TagTypes>) {
    return async function makeRequest<
        Name extends keyof Definitions,
        Params extends (typeof api)['endpoints'][Name]['Types']['QueryArg'],
    >(
        ...args: Params extends void ? [Name] : [Name, Params]
    ): Promise<(typeof api)['endpoints'][Name]['Types']['ResultType']> {
        const [name, params] = args;

        const initiate = api.endpoints[args[0]].initiate;

        return sendRequest({
            send: () => initiate(params),
            endpointName: name as string,
        });
    };
}
